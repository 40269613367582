import React from "react"
import { Fade } from "react-reveal"

export default function PageIntro({ text }) {
  return (
    <div className="bg-secondary/10 h-[33vh] flex flex-col items-center justify-center">
      <div className="max-w-4xl mx-auto px-4 lg:px-8">
        <Fade left distance="100px">
          <h3 className="text-2xl lg:text-4xl">{text}</h3>
        </Fade>
      </div>{" "}
      {/* <div className="max-w-xl mx-auto px-8 py-16">
      <div className="rounded-full h-full overflow-hidden bg-secondary  mx-8">
        <div className="rounded-full overflow-hidden relative z-0">
          <FeaturedImage image={featuredImage} />
        </div>
      </div>
    </div> */}
    </div>
  )
}
