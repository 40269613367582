import React from "react"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedImage from "../components/common/FeaturedImage"
import PageIntro from "../components/PageIntro"
export default function OmMigPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 14 }) {
        content
        sidinfo {
          introRubrikSida
        }
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)
  const { content, featuredImage, title, sidinfo } = data.wpPage
  return (
    <Layout>
      <SEO title="Om mig" />
      <PageIntro text={sidinfo?.introRubrikSida || title} />
      <div className="single-page-inner">
        <div className="small-container">
          <div className="flex flex-col items-center gap-8">
            <div className="flex-1 order-last space-y-8">
              {/* <PageHeader text={title} /> */}
              <div
                className="wp-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="small-container ">
        <div className="space-y-8">
          <div className="flex flex-col items-center gap-8">
            <div className="flex-1 order-last space-y-8">
             
              <div
                className="wp-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
            <div className="flex-1 max-w-xl">
              <div className="rounded-full h-full overflow-hidden bg-secondary  mx-8">
                <div className="rounded-full overflow-hidden relative z-0">
                  <FeaturedImage image={featuredImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}
